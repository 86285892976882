<template>
  <div>
    <h3>Add product</h3>
    <!-- <v-btn
      text
      :to="{ name: 'manage-websites', params: { domainname: siteUrl }}"
      outlined
      class="btn btn-lg btn-secondary-main"
      style="background:transparent;"
    >
      <i class="fas fa-arrow-left"></i> Back
    </v-btn>-->
    <v-app>
      <!-- <pre>{{product}}</pre> -->
      <v-card>
        <v-card-text>
          <v-container>
            <v-row>
              <v-col>
                <v-select
                    v-model="product.type"
                    :items="types"
                    dense
                    item-text="text"
                    item-value="value"
                    label="Your Product Type"
                    outlined
                ></v-select>
<!--                <v-radio-group v-model="product.type" row>-->
<!--                  <template v-slot:label>-->
<!--                    <div>-->
<!--                      Your Product-->
<!--                      <strong>Type</strong>-->
<!--                    </div>-->
<!--                  </template>-->
<!--                  <v-radio value="digital">-->
<!--                    <template v-slot:label>-->
<!--                      <v-card class="mx-auto" max-width="">-->
<!--                        <progressive-img-->
<!--                          class="white&#45;&#45;text align-end"-->
<!--                          max-height="80"-->
<!--                        >-->

<!--                        </progressive-img>-->
<!--                        <v-card-title>-->
<!--                          <strong class="success&#45;&#45;text">Digital Download</strong>-->
<!--                        </v-card-title>-->
<!--                        <v-card-subtitle class="pb-0">-->
<!--                          <small>e.g, e-book,printable</small>-->
<!--                        </v-card-subtitle>-->
<!--                      </v-card>-->
<!--                    </template>-->
<!--                  </v-radio>-->
<!--                  <v-radio value="physical">-->
<!--                    <template v-slot:label>-->
<!--                      <v-card class="mx-auto" max-width="200">-->
<!--                        <progressive-img-->
<!--                          class="white&#45;&#45;text align-end"-->
<!--                          max-height="80"-->
<!--                        >-->

<!--                        </progressive-img>-->
<!--                        <v-card-title>-->
<!--                          <strong class="success&#45;&#45;text">Physical Product</strong>-->
<!--                        </v-card-title>-->
<!--                        <v-card-subtitle class="pb-0">-->
<!--                          <small>e.g,t-shirt,shoes,goods</small>-->
<!--                        </v-card-subtitle>-->
<!--                      </v-card>-->
<!--                    </template>-->
<!--                  </v-radio>-->
<!--                  <v-radio value="service">-->
<!--                    <template v-slot:label>-->
<!--                      <v-card class="mx-auto" max-width="200">-->
<!--                        <progressive-img-->
<!--                          class="white&#45;&#45;text align-end"-->
<!--                          max-height="80"-->
<!--                        >-->

<!--                        </progressive-img>-->
<!--                        <v-card-title>-->
<!--                          <strong class="success&#45;&#45;text">Service Product</strong>-->
<!--                        </v-card-title>-->
<!--                        <v-card-subtitle class="pb-0">-->
<!--                          <small>e.g,t-subscription,ticket</small>-->
<!--                        </v-card-subtitle>-->
<!--                      </v-card>-->
<!--                    </template>-->
<!--                  </v-radio>-->
<!--                </v-radio-group>-->
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12">
                <v-text-field
                  outlined
                  dense
                  v-model="product.title"
                  label="Enter product name"
                ></v-text-field>
                <span class="text-danger" v-if="$v.product.title.$error">Title field is required</span>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12">
                <treeselect
                    :multiple="true"
                    :options="catOptions"
                    :sort-value-by="sortValueBy"
                    placeholder="Choose catalogs"
                    v-model="product.catalog_ids"
                    :value-consists-of="valueConsistsOf"
                    :disable-branch-nodes="false"
                />
              </v-col>
            </v-row>
<!--            <v-row>-->
<!--              <v-col cols="12">-->
<!--                <cascader-->
<!--                  style="width:100%;"-->
<!--                  size="large"-->
<!--                  :options="options"-->
<!--                  v-model="selected"-->
<!--                  :load-data="loadData"-->
<!--                  :show-search="{ filter }"-->
<!--                  :fieldNames="{-->
<!--                    label: 'title', value: 'id', children: 'children'-->
<!--                    }"-->
<!--                  placeholder="Start typing to select best category"-->
<!--                  change-on-select-->
<!--                  @search="onChange"-->
<!--                />-->
<!--                &lt;!&ndash; <span-->
<!--                  class="text-danger"-->
<!--                  v-if="$v.product.marketplace_catalog_ids.$error"-->
<!--                >Category field is required</span>&ndash;&gt;-->
<!--              </v-col>-->
<!--            </v-row>-->
            <v-row v-if="errors != undefined">
              <v-col>
                <div v-for="(error, index) of errors" :key="index">
                  <li class="text-danger" v-for="(e,i) in error" :key="i">
                    <strong>{{e}}</strong>
                  </li>
                </div>
              </v-col>
            </v-row>
            <v-card-actions class="pull-right">
              <v-btn @click="validateForm" class="btn btn-primary">
                Save & Continue
                <i class="fa fa-arrow-right ml-2"></i>
              </v-btn>
            </v-card-actions>
          </v-container>
        </v-card-text>
      </v-card>
    </v-app>
    <v-overlay :value="overlay">
      <v-progress-circular indeterminate size="64"></v-progress-circular>
    </v-overlay>
  </div>
</template>
<script>
import { required } from "vuelidate/lib/validators";
import Cascader from "ant-design-vue/lib/cascader";
import "ant-design-vue/dist/antd.css";
import "@riophae/vue-treeselect/dist/vue-treeselect.css";
import ProductCatalogRootService from "@/services/store/ProductCatalogRootService";
const rootCatalogService = new ProductCatalogRootService();
import WebsiteMarketplaceCatlalogs from "@/services/Websites/WebsiteMarketPlaceCatalogsService";
const WebsiteMarketPlaceCatlalogsService = new WebsiteMarketplaceCatlalogs();
import ProductService from "@/services/store/ProductService";
import Treeselect from "@riophae/vue-treeselect";
const Product = new ProductService();
export default {
  name: "product-add-name",
  validations: {
    product: { title: { required } }
  },
  data: () => ({
    errors: {},
    radios: "",
    overlay: false,
    editorConfig: {
      toolbar: [["Bold", "Italic", "Underline"]]
    },
    sortValueBy: "ORDER_SELECTED",
    valueConsistsOf: 'ALL_WITH_INDETERMINATE',
    catOptions: [],
    normalizer(node) {
      return {
        id: node.id,
        label: node.title,
        children: node.children
      };
    },
    types:[
      {
        text:"Digiital",
        value:"digital"
      },
      {
        text:"Physical",
        value:"physical"
      },
      {
        text:"Service",
        value:"service"
      }
    ],
    options: [
      // {
      //   value: "zhejiang",
      //   label: "Zhejiang",
      //   isLeaf: false
      // },
      // {
      //   value: "jiangsu",
      //   label: "Jiangsu",
      //   isLeaf: false
      // }
    ],
    product: {
      title: "",
      marketplace_catalog_ids: "",
      type: "physical"
    },
    selected: null
  }),
  components: {
    Cascader,
    treeselect: Treeselect
  },
  methods: {
    onChange(value) {
      if (value.length >= 3) {
        this.getCatlogs(value);
      }
    },
    filter(inputValue, path) {
      return path.some(
        option =>
          option.title.toLowerCase().indexOf(inputValue.toLowerCase()) > -1
      );
    },
    loadData(selectedOptions) {
      const targetOption = selectedOptions[selectedOptions.length - 1];
      targetOption.loading = true;

      // load options lazily
      setTimeout(() => {
        targetOption.loading = false;
        targetOption.children = [
          {
            label: `${targetOption.label} Dynamic 1`,
            value: "dynamic1"
          },
          {
            label: `${targetOption.label} Dynamic 2`,
            value: "dynamic2"
          }
        ];
        this.options = [...this.options];
      }, 1000);
    },
    getCatlogs(value = null) {
      WebsiteMarketPlaceCatlalogsService.paginate(value).then(res => {
        this.options = res.data;
        this.overlay = false;
      });
    },
    validateForm() {
      if (this.selected) {
        this.product.marketplace_catalog_ids = this.selected;
      }
      this.$v.product.$touch();
      if (this.$v.product.$error) {
        setTimeout(() => {
          this.$v.product.$reset();
        }, 3000);
      } else {
        this.createProduct();
      }
    },
    createProduct() {
      let data = this.product;
      data.marketplace_catalog_ids = this.selected;
      Product.create(data)
        .then(res => {
          this.$snotify.success("New Product Added Successfully");
          this.$router.push({
            name: "product-detail",
            params: { productid: res.data.product.id }
          });
        })
        .catch(error => {
          this.errors = error.response.data.errors;
        })
        .finally(() => {
          this.loading = false;
        });
    },
    getCatalogs() {
      rootCatalogService
          .getAll()
          .then(response => {
            if (response.data.length > 0) {
              response.data.forEach(ele => {
                ele.catalogs.forEach(e => {
                  this.catOptions.push(e);
                });
              });
            }
          })
          .catch(error => {});
    },
  },
  computed: {
    catlog() {
      return this.selected.join();
    }
  },
  mounted() {
    this.getCatalogs();
  }
};
</script>
