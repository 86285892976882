import Apiservice from '../../common/api.service';
import { API_URL } from '../../common/config.js'
const apiService = Apiservice;


export default class WebsiteMarketPlacecatalogsService {


    paginate(value=null, index = null) {
        let url = API_URL + 'user/market-place/catalogs';
        if (value != null)
            url = url + '?by=' + value
        if (index != null)
            url = url + '?page=' + index
        return apiService.get(url);
    }



    create(slug, data) {
        let url = API_URL + 'user/market-place/catalogs';
        return apiService.post(url, data);
    }

    update(slug, id, data) {
        let url = API_URL + 'user/market-place/catalogs/' + id+'/update'
        return apiService.post(url, data);
    }

    delete(slug, id) {
        let url = API_URL + 'user/market-place/catalogs/' + id
        return apiService.delete(url);
    }
    show(slug, id) {
        let url = API_URL + 'user/market-place/catalogs/' + id
        return apiService.get(url);
    }


}
